import React from "react";
import footerImg from "../images/footer_img.png";
import new_logo from "../images/new_logo.png";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiOutlineTwitter,
  AiFillMail,
  AiFillLinkedin,
  AiOutlineBehance,
  AiFillBehanceCircle
} from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-[#345fe0dd] flex flex-wrap items-center gap-10 justify-around pt-10">
      <div className="w-[90%] sm:w-[50%] md:w-[30%] flex flex-col items-center text-justify sm:text-start sm:items-start gap-5 text-white">
        <img src={new_logo} className="w-20 " />
        <p className="">
          Crafting Tomorrow's Solutions, Today – Social Syncing is your strategic
          partner for technology consultancy. Our expert team specializes in IT
          strategies, software solutions, cybersecurity, and digital
          transformation. With a focus on innovation and excellence, we empower
          businesses to thrive in the digital age. Join us on the journey
          towards success.
        </p>
        <h4 className="flex items-center gap-2">
          <AiFillMail /> contact@socialsyncing.world
        </h4>
        <h4 className="flex items-center gap-2">
          <BsFillTelephoneFill />
          +91-8805598436
          
        </h4>
        <h4 className="flex items-center gap-2">
          <BsFillTelephoneFill />
          +91-9111884992
          
        </h4>

        
        {/* <div className="flex gap-5">
        <button>
          <Link
          to="https://www.behance.net/socialsyncing">

          
            <AiFillBehanceCircle
              size={40}
              className="text-[#3C5A99] bg-white hover:bg-[#3C5A99] hover:text-white rounded-full px-2 "
            />
            </Link>
          </button>
          
          <button>
          <Link
          to="https://www.instagram.com/socialsyncing/">
            <AiFillInstagram
              size={40}
              className="text-[#3C5A99] bg-white hover:bg-[#3C5A99] hover:text-white rounded-full px-2"
            />
            </Link>
          </button>
          <button>
          <Link
          to="https://www.linkedin.com/company/thesocialsyncing/">
            <AiOutlineLinkedin
              size={40}
              className="text-[#3C5A99] bg-white hover:bg-[#3C5A99] hover:text-white rounded-full px-2"
            />
            </Link>
          </button>
          <button>
          <Link
          to="https://www.facebook.com/socialsyncing/">
            <AiFillFacebook
              size={40}
              className="text-[#3C5A99] bg-white hover:bg-[#3C5A99] hover:text-white rounded-full px-2 "
            />
            </Link>
          </button>
        </div> */}
      </div>
      <div className="w-[90%] sm:w-[50%] md:w-[30%] flex flex-col items-center text-justify sm:text-start sm:items-start gap-5 text-white">        <h3 className="text-white text-xl"><b>Useful Links</b></h3>
        <Link
          to="/home/section"
          className={`text-lg text-white hover:underline px-3 rounded-full`}
        >
          Home
        </Link>
        <Link
          to="/about/section"
          className={`text-lg text-white hover:underline px-3 rounded-full`}
        >
          About Us
        </Link>
        <Link
          to="/services/section"
          className={`text-lg text-white hover:underline px-3 rounded-full`}
        >
          Services
        </Link>
        {/* <Link
          to="/refund/section"
          className={`text-lg text-white hover:underline px-3 rounded-full`}
        >
          Cancelation and Refund Policy
        </Link> */}
        <Link
          to="/terms/section"
          className={`text-lg text-white hover:underline px-3 rounded-full`}
        >
          Terms & Conditions
        </Link>
        <Link
          to="/privacy/section"
          className={`text-lg text-white hover:underline px-3 rounded-full`}
        >
          Privacy Policy
        </Link>
        <Link
          to="/contact/section"
          className={`text-lg text-white hover:underline px-3 rounded-full`}
        >
          Contact Us
        </Link>
      </div>
      <div className="w-[90%] sm:w-[50%] md:w-[30%] flex flex-col items-center text-justify sm:text-start sm:items-start gap-5 text-white">      <h3 className="text-white text-xl"><b>Our Socials</b></h3>
        <button>
          <Link
          to="https://www.behance.net/socialsyncing">

          
            <AiFillBehanceCircle
              size={40}
              className="text-[#3C5A99] bg-white hover:bg-[#3C5A99] hover:text-white rounded-full px-2 "
            />
            </Link>
            
          </button>
          
          <button>
          <Link
          to="https://www.instagram.com/socialsyncing/">
            <AiFillInstagram
              size={40}
              className="text-[#3C5A99] bg-white hover:bg-[#3C5A99] hover:text-white rounded-full px-2"
            />
            </Link>
          </button>
          <button>
          <Link
          to="https://www.linkedin.com/company/thesocialsyncing/">
            <AiFillLinkedin
              size={40}
              className="text-[#3C5A99] bg-white hover:bg-[#3C5A99] hover:text-white rounded-full px-2"
            />
            </Link>
          </button>
          <button>
          <Link
          to="https://www.facebook.com/socialsyncing/">
            <AiFillFacebook
              size={40}
              className="text-[#3C5A99] bg-white hover:bg-[#3C5A99] hover:text-white rounded-full px-2 "
            />
            </Link>
          </button>
        </div>
      {/* <div className="w-[70%] sm:w-[50%] md:w-[30%] flex">
        <img src={footerImg} className="w-[100%] " alt="" />
      </div> */}
    </footer>
  );
};

export default Footer;
