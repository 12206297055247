import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

const ContactModal = (props) => {
  const [value, setValue] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleOnChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };
  return (
    <div
      className={
        "z-50 fixed h-full w-full flex items-center justify-center " +
        props.className
      }
    >
      <div
        className="h-[110vh] w-full bg-[#000000aa] backdrop-blur-md absolute cursor-pointer"
        onClick={() => {
          props.showHandler(false);
        }}
      ></div>
      <div className=" h-[90%] w-[90%] sm:w-[60%] md:w-[35%] bg-white absolute rounded-3xl animate-swipeIn">
        <form className="flex flex-col justify-center gap-5 p-7 h-full shadow-xl relative">
          <AiOutlineClose
            size={25}
            className="absolute right-7 top-7 cursor-pointer"
            onClick={() => {
              props.showHandler(false);
            }}
          />
          <div className="text-[#345fe0]">
            <h3 className="text-md">Have something in mind?</h3>
            <h2 className="text-3xl font-semibold">Connect with Us</h2>
          </div>
          <input
            name="name"
            onChange={handleOnChange}
            type="text"
            placeholder="Name"
            className="bg-gray-200 p-2 rounded-lg focus:outline-none"
          />
          <input
            name="phone"
            onChange={handleOnChange}
            type="text"
            placeholder="Mobile No."
            className="bg-gray-200 p-2 rounded-lg focus:outline-none"
          />
          <input
            name="email"
            onChange={handleOnChange}
            type="email"
            placeholder="Email address"
            className="bg-gray-200 p-2 rounded-lg focus:outline-none"
          />
          <textarea
            name="message"
            onChange={handleOnChange}
            rows={5}
            placeholder="Message"
            className="bg-gray-200 px-2 py-3 rounded-lg focus:outline-none"
          />
    
      
          <a
            href={`https://wa.me/918805598436?text=${encodeURIComponent(
              `Message from ${value.name}\n${value.message}\nName: ${value.name}\nContact no: ${value.phone}\nEmail: ${value.email}`
            )}`}
            target="_blank"
            onClick={() => {
              console.log(value.message);
            }}
            className="hover:bg-white hover:text-[#262e45] text-lg font-[600] py-2 mx-auto px-8 rounded-xl text-white bg-[#345fe0] transition-colors duration-400 cursor-pointer"
          >
            Send Message
          </a>
        </form>
      </div>
    </div>
  );
};

export default ContactModal;
