import React, { useEffect, useRef } from "react";
import ServiceCard from "../components/Services/ServiceCard";

const Services = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    // Scroll to the sectionw hen the component mounts
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const ServiceCardData = [
    {
      title: "Web Development",
      description:
        "Elevate your online presence with our cutting-edge web development services. From responsive designs to seamless user experiences, we create websites that captivate audiences and drive business growth. Our expert developers blend creativity and functionality to deliver websites that leave a lasting impression.",
    },
    {
      title: "App Development",
      description:
        "Transform your ideas into dynamic mobile experiences with our app development prowess. We specialize in crafting user-friendly and feature-rich applications for iOS and Android platforms. Whether it's for business or consumer needs, our apps are built to engage, inspire, and deliver exceptional value.",
    },
    {
      title: "Web3 & Metaverse",
      description:
        "Unlocking new realities. Crafting immersive Eexperiences and future ready solutions in the metaverse and web3",
    },
    {
      title: "Digital Marketing",
      description:
        "Navigate the digital landscape with our results-driven digital marketing strategies. From SEO and social media to content creation and paid campaigns, we orchestrate comprehensive approaches that boost your brand's visibility, engagement, and conversion rates. Let us optimize your digital footprint for success.",
    },
    {
      title: "Logo Desinging",
      description:
        "Crafting unique and memorable logos that resonate with your brand’s identity. Our designs ensure strong brand recall and market differentiation.",
    },
    {
      title: "Brand Identity",
      description:
        "Building cohesive brand identities that reflect your company’s core values and vision. We create visual assets that elevate your brand’s presence across platforms.",
    }, {
      title: "Graphic Designing",
      description:
        "Delivering eye-catching graphic designs tailored to your business needs. From marketing materials to digital content, we bring your ideas to life.",
    }, {
      title: "Video Editing",
      description:
        "Producing engaging and high-quality videos that tell your story. We edit content to captivate audiences and enhance your brand’s narrative.",
    },{
      title: "CGI & 3D Videos",
      description:
        "Creating stunning CGI and 3D visuals to immerse viewers in dynamic, interactive experiences. Ideal for product demos, animations, and visual storytelling.",
    },{
      title: "Social Media Management",
      description:
        "Strategically managing your social media profiles to boost engagement and grow your online presence. We handle content, interactions, and insights.",
    },{
      title: "Social Media Marketing",
      description:
        "Crafting targeted campaigns that maximize your reach and conversions on platforms like Facebook, Instagram, and LinkedIn. We drive results with precision.",
    },{
      title: "Google Ads",
      description:
        "Developing and optimizing Google Ads campaigns to ensure maximum ROI. We help you reach the right audience with tailored search, display, and video ads.",
    },{
      title: "Brand Shoots at our own studio",
      description:
        "Offering professional brand shoots at our in-house studio. Capture the essence of your business through high-quality photos and videos that speak to your audience.",
    },{
      title: "Ai Chatbots",
      description:
        "Implementing intelligent AI chatbots to automate customer interactions and provide real-time support. Enhance user experience with seamless automation.",
    },
    {
      title: "Ai Automations",
      description:
        "Integrating AI-powered automation to streamline workflows and improve efficiency. Let technology handle repetitive tasks while you focus on growth.",
    },
    {
      title: "WhatsApp Marketing",
      description:
        "Leveraging the power of WhatsApp to connect with customers on a personal level. Our WhatsApp marketing solutions help you engage and convert leads.",
    },
  ];
  return (
    <section ref={sectionRef}>
      <div className="bg-[#345fe0dd] h-16"></div>
      <section className=" py-16 px-4 md:px-16 ">
        <h2 className="text-4xl font-semibold text-center pb-10">
          Our Services
        </h2>
        <div className=" rounded-2xl grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-10 relative">
          {ServiceCardData.map((card) => {
            return (
              <ServiceCard
                title={card.title}
                description={card.description}
                type="s_card"
              />
            );
          })}
        </div>
      </section>
    </section>
  );
};

export default Services;
